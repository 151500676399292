<template>
  <span :class="numberClass">$ {{ formatted }}<small v-if="postfix">/{{ postfix }}</small></span>
</template>
<script>
export default {
  name: 'Dollars',
  props: [ 'value', 'postfix' ],
  computed: {
    numberClass: function () {
      let float = parseFloat(this.value)
      if (float < 0) {
        return 'negative'
      } else {
        return 'positive'
      }
    },
    formatted: function () {
      let dollars = '0.00'
      if (this.value && (typeof this.value === 'number' || typeof this.value === 'string')) {
        let float = parseFloat(this.value)
        dollars = float.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }
      return dollars
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  &.colorize {
    &.negative {
      color: #d33200;
    }
  }
}
</style>

